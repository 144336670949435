//京普科技是区块链、云计算、物联网、大数据等技术的先行者，积极运用新技术探索行业解决方案。公司致力于提供一体化的解决方案，目前公司产品矩阵包含智慧社区解决方案、金融科技解决方案、人力资源解决方案、企业信息化解决方案。公司已经和多个行业领军企业建立了合作关系。
import React from 'react';
import logo from '../../../img/logo.png';
import costimg from '../../../img/chengben.png';
import completeimg from '../../../img/wanzheng.png';
import performimg from '../../../img/gaoxingneng.png';
import expandimg from '../../../img/tuozhan.png';
import appimg from '../../../img/app.png';
import appicon from '../../../img/appicon.png';
import systemleftimg from '../../../img/systemleft.png';
import wxcode from '../../../img/wxcode.png';
import { Divider } from 'antd';
import {Col, Row} from 'antd';
import { Menu, Dropdown, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import Sliderarrow from './Sliderarrow';
import dqdimg from '../../../img/duoqudao.png';
import dyhimg from '../../../img/duoyuanhua.png';
import higheffimg from '../../../img/gaoxiao.png';
import ktzimg from '../../../img/ketuozhan.png';
import moneyimg from '../../../img/caiwu.png';
import propertyimg from '../../../img/zichan.png';
import bm from '../../../img/bm.png';
import dsj from '../../../img/dsj.png';
import jd from '../../../img/jd.png';
import jk from '../../../img/jk.png';
import ll from '../../../img/ll.png';
import pa from '../../../img/pa.png';
import zb from '../../../img/zb.png';
import zh from '../../../img/zh.png';
import computer from '../../../img/computer2.png';
import dangjian from '../../../img/jiandang.png';
import car from '../../../img/car.png';
import fire from '../../../img/huozai.png';
import face from '../../../img/renlian.png';
import tabslogo from '../../../img/tabslogo.png';
import property from '../../../img/wuye.png';
import landlord from '../../../img/yezhu.png';
import backstage from '../../../img/houtai.png';
import backlogo from '../../../img/back-logo.png';
import gaokong from '../../../img/gaokong.png';
import zhoujie from '../../../img/zhoujie.png';
import Sliderarrow1 from './Sliderarrow1';
import Sliderarrow2 from './Sliderarrow2';
const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/community'>智慧社区</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/humanresource'>人力资源</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/companyinfo' >集团信息化</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/finance' >金融科技</Link>
    </Menu.Item>
  </Menu>
);
const { TabPane } = Tabs;

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              解决方案
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about' style={{color: "#fff"}}>联系我们</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71so',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/us' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Intelligent Commuinty',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '智慧社区解决方案',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '综合了大数据、区块链和物联网等技术的“蓝巷”智慧社区平台已经上线，并且和200多家社区达成合作入驻协议。',
      },
      { name: 'button', className: 'banner3-button', children: '' },
    ],
  },
};
export const Feature02DataSource = {
  wrapper: { className: 'home-page-wrapper content02-wrapper' },
  page: { className: 'home-page content02' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品优势' }],
  },
  childWrapper: {
    className: 'content02-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content02-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content02-block-item',
          children: [
            {
              name: 'image',
              className: 'content02-block-icon',
              children: (<img src={costimg} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content02-block-title',
              children: '功能定制化',
            },
            { name: 'content', children: '核心能力层的各能力采用低耦合、组件化设计，目的是快速响应各类型用户对视频交互系统的不同需求。' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content02-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content02-block-item',
          children: [
            {
              name: 'image',
              className: 'content02-block-icon',
              children: (<img src={completeimg} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content02-block-title',
              children: '无限输出',
            },
            {
              name: 'content',
              children: '系统理论上支持无限量的用户同时观看音视频安防内容，视所部署环境计算资源及带宽资源条件而定。',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content02-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content02-block-item',
          children: [
            {
              name: 'image',
              className: 'content02-block-icon',
              children: (<img src={performimg} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content02-block-title',
              children: '多终端交互',
            },
            {
              name: 'content',
              children: '用户展现层可支持手机、电脑桌面、电脑、平板。触控一体机、专业向终端等，为用户提供了全面的选择',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content02-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content02-block-item',
          children: [
            {
              name: 'image',
              className: 'content02-block-icon',
              children: (<img src={expandimg} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content02-block-title',
              children: '移动化扩展',
            },
            {
              name: 'content',
              children: '可无缝对接各类系统，满足客户对已部署交互系统进行移动化拓展的需求。',
            },
          ],
        },
      },
    ],
  },
};
export const Feature71DataSource = {
  wrapper: { className: 'home-page-wrapper feature71-wrapper' },
  page: { className: 'home-page feature71' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature71-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature71-title-h1',
        children: '核心功能',
      },
      {
        name: 'content',
        className: 'feature71-title-content',
        children: '实现城市智慧民生',
      },
    ],
  },
  blockWrapper: {
    className: 'feature71-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children:(<img src={dsj} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '综合大数据分析平台',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '具备基础要素信息汇聚的能力，能与区（市）级智慧安防小区管理系统对接。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children: (<img src={jd} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '党建引领',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '为党员建立交流平台，为党建工作搭建便捷、高效的沟通平台。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children:(<img src={pa} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '平安社区',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '集人脸识别、体温检测、车牌识别、火灾识别等为一体的综合安防体系。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children:(<img src={bm} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '便民服务',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '通过该平台对接街道办事处提供的电子政务系统，从而实现社区政务的在线化。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children:(<img src={ll} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '邻里互动',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '邻里间通过平台互动沟通，邻里心情；可以在闲置中查看本小区和附件小区的闲置物品',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children:(<img src={zb} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '周边社区',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '居民可以享受更高质量的生活服务，相比于电商有更多的保障。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children:(<img src={jk} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '健康社区',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '智慧医疗服务将社区居民与社区就近社区诊所打通，实现线上问诊挂号。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature71-block',
        children: {
          className: 'feature71-block-group',
          children: [
            {
              name: 'image',
              className: 'feature71-block-image',
              children:(<img src={zh} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature71-block-title',
              children: '智慧物业',
            },
            {
              name: 'content',
              className: 'feature71-block-content',
              children: '包括相互配合的业主客户端、物业客户端、以及与之相匹配的后台管理系统。',
            },
          ],
        },
      },
    ],
  },
};
export const Content133DataSource = {
  OverPack: {
    className: 'home-page-wrapper content133-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '综合大数据分析平台', className: 'title-h1' },
      { name: 'content', children: '具备基础要素信息汇聚的能力，能与区（市）级智慧安防小区管理系统对接。', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <Row justify="center">
            <Col lg={7} md={24} className="content133-system-left">
              <div className="content133-text-wrapper">
                <div className="content133-text-title1"><span className="content133-text-blue"></span>综合大数据分析平台</div>
                <div className="content133-text-content">综合大数据平台具备基础要素信息汇聚的能力，能与区（市）级智慧安防小区管理系统对接。</div>
                <div className="content133-text-content">系统整合周界入侵报警、门禁、人脸识别、车辆管理、视频监控、安全监测、人口管理、房屋管理等子系统，实现综合应用管理以及分析。</div>
                <div className="content133-text-content">在综合平台之上可以拓展建立警民联动、特殊人群监管、社区网格化管理等，丰富平台应用场景。</div>
              </div>
            </Col>
            <Col lg={12} md={24} className="content133-system-right"><img src={computer} className="content133-right-img" alt="system" /></Col>
          </Row>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content139DataSource = {
  OverPack: {
    className: 'home-page-wrapper content139-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '党建引领', className: 'title-h1' },
      { name: 'content', children: '为党员建立交流平台，为党建工作搭建便捷、高效的沟通平台', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <Row justify="center">
            <Col lg={10} md={24} className="content139-system-right"><img src={dangjian} className="content139-right-img" alt="system" /></Col>
            <Col lg={7} md={24} className="content139-system-left">
              <div className="content139-text-wrapper">
                <div className="content139-text-title1"><span className="content139-text-blue"></span>党建引领</div>
                <div className="content139-text-content">党员是构成政党的基石和细胞，党员的精神面貌在很大程度上决定政党的水平，因此党建工作就显得尤其重要。</div>
                <div className="content139-text-content">“蓝巷”设置的党建引领模块可以为党员建立交流平台，为党建工作搭建便捷、高效的沟通平台，覆盖协作、请示、汇报、交流、讨论等各项党建事务应用，实现党员管理，党费缴纳，公告发布，党建互动，党建培训，党建管理的信息化，全面提升党建效率。</div>
              </div>
            </Col>
          </Row>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Feature52DataSource = {
  wrapper: { className: 'home-page-wrapper content72-wrapper' },
  page: { className: ' content72' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '平安社区',
        className: 'title-h1',
      },
      {
        name: 'content',
        children: '集人脸识别、 体温检测、车牌识别、火灾识别、 等为一体的综合安防体系。',
        className: 'content72-title-explain',
      },
      { name: 'content',
        children: (
          <Tabs defaultActiveKey="1" >
            <TabPane tab="车辆出入管理" key="1">
              <div className="content72-bottom-text">外来车辆进入小区，对车牌进行识别，并辨识与比对是否为月卡(白名单)，或者是陌生车辆(灰名单)， 还是之前有逃费积累的黑名单车辆，识别完对入场时间进行录入， 并进行抓图与录制小视频存档，车辆离场时进行对白名单进行识别与放行， 对灰名单进行计时收费，同时做抓图、小视频存档。</div>
              <div><img src={car} alt="apply" /></div>
            </TabPane>
            <TabPane tab="人员出入管理" key="2">
              <div className="content72-bottom-text">对于人员出入，主要有两块，一是小区大门口的出入管理，二是单元楼出入管理。这两个出入口都可以采用人脸识别们禁，原则上人脸门禁出入要求是一脸一入，对于一脸入多人进行提示，并对跟随进入的人脸进行抓图、识别比对，如果跟随人员人脸也属于小区白名单则通过， 如果是陌生人员则做访客登记。</div>
              <div><img src={face} alt="apply" /></div>
            </TabPane>
            <TabPane tab="火灾监控防范" key="3">
              <div className="content72-bottom-text">针对机房、物业仓库、垃圾站安装火灾识别相机，该摄像机火 灾识别的原理是基于视觉AI神经元网络算法，对火焰进行识别，同时结合 光频段信号分析，也可以接连烟感、温感作为附件判断条件 。该子系统除了担当火灾检测的重任，同时还依然保留与具备安防音视频监控的作用，一机两用，从而实现设备价值最大化被利用。</div>
              <div><img src={fire} alt="apply" /></div>
            </TabPane>
            <TabPane tab="周界防范" key="4">
              <div className="content72-bottom-text">针对小区围墙，我们采用了可视化电子围墙代替传统红外对射周界系统，不仅大大降低误报率，同时还实现可视化与智能化，更加有效防范翻墙跨界进入小区</div>
              <div><img src={zhoujie} alt="apply" /></div>
            </TabPane>
            <TabPane tab="高空抛物监控" key="5">
              <div className="content72-bottom-text">采用楼宇高空抛物专用摄像机该摄像机与围墙相机有一个共同之处，就是画面顺着高楼从上至下监控，由于高空抛物相机是从下超上监控而天空往往非常明亮甚至有太阳出现在画面，因此摄像机必须要支持强光抑制功能，另外针对夜晚监控，该相机还应支持星光全彩。</div>
              <div><img src={gaokong} alt="apply" /></div>
            </TabPane>
          </Tabs>
      ),
        className: 'content72-text-wrapper'
    },
    ],
  },
  tabsWrapper: { className: 'content72-tabs-wrapper' },
};
export const Feature53DataSource = {
  wrapper: { className: 'home-page-wrapper content73-wrapper' },
  page: { className: ' content73' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '智慧物业',
        className: 'title-h1',
      },
      {
        name: 'content',
        children: '包括相互配合的业主客户端、物业客户端，以及与之相匹配的后台管理系统。',
        className: 'content73-title-explain',
      },
      { name: 'content',
        children: (
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="业主客户端" key="1">
              <Row justify="center" className="content73-tabs-content">
                <Col span={6} offset={6}>
                  <div><img src={tabslogo} alt="apply" className="content73-app-logo" /></div>
                  <div className="content73-app-title">蓝巷智慧物业APP</div>
                  <div className="content73-bottom-text">系统包含物业缴费，生活缴费，物业报修，车辆管理，访客，投诉表扬，问卷调查等模块目前“蓝巷”已经基本完成物业的管理需求。</div>
                  <div><span className="content73-download">下载体验<div id="content73-qrcode"></div></span><a className="content73-searchmore" href="https://lanxiang.co/#/index" target="_blank">查看更多</a></div>
                </Col>
                <Col span={12}>
                  <img src={landlord} alt="img" />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="物业客户端" key="2">
              <Row justify="center" className="content73-tabs-content">
                <Col span={6} offset={6}>
                  <div><img src={tabslogo} alt="apply" className="content73-app-logo" /></div>
                  <div className="content73-app-title">蓝巷智慧物业APP</div>
                  <div className="content73-bottom-text">“蓝巷”物业版块是为“智慧社区”构建的一个社区智能化管理平台，通过物业管理服务， 市民 可简单通过“蓝巷”便捷、高效地体验物业管理、生活服务、 便民服务、公共服务等</div>
                  <div><span className="content73-download">下载体验<div id="content73-qrcode"></div></span><a className="content73-searchmore" href="https://lanxiang.co/#/index" target="_blank">查看更多</a></div>
                </Col>
                <Col span={12}>
                  <img src={property} alt="img" />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="后台管理系统" key="3">
              <Row justify="center" className="content73-tabs-content">
                <Col span={6} offset={6}>
                  <div><img src={backlogo} alt="apply" className="content73-app-logo" /></div>
                  <div className="content73-app-title">后台管理系统</div>
                  <div className="content73-bottom-text">物业公司可以拥有独立、 完善的应用系统，减少系统之间匹配度的问题，便于提高物业管 理和运营效率。同时由于 Saas 系统的灵活性，物业公司可以根据 自己需求选取相应的功能板块，能够有效降低物业公司的运营成本。</div>
                </Col>
                <Col span={12}>
                  <img src={backstage} alt="img" />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
      ),
        className: 'content73-text-wrapper'
    },
    ],
  },
  tabsWrapper: { className: 'content73-tabs-wrapper' },
  // block: {
  //   children: [
  //     {
  //       name: 'block0',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'PHONE', className: 'content7-tag-name' },
  //         icon: { children: 'mobile' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //           md: 10,
  //           xs: 24,
  //         },
  //       },
  //     },
  //     {
  //       name: 'block1',
  //       tag: {
  //         className: 'content7-tag',
  //         icon: { children: 'tablet' },
  //         text: { className: 'content7-tag-name', children: 'TABLET' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //     {
  //       name: 'block2',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'DESKTOP', className: 'content7-tag-name' },
  //         icon: { children: 'laptop' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //     {
  //       name: 'elem~k93rlp61wrk',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'DESKTOP', className: 'content7-tag-name' },
  //         icon: { children: 'laptop' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //     {
  //       name: 'elem~k93rlps6rda',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'DESKTOP', className: 'content7-tag-name' },
  //         icon: { children: 'laptop' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //   ],
  // },
};
export const Content1310DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1310-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '案例展示', className: 'title-h1' },
      { name: 'content', children: '综合了大数据、区块链和物联网等技术的 “蓝巷”智慧社区平台已经上线，并且和200多家社区达成合作入驻协议。', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <Tabs defaultActiveKey="1" >
            <TabPane tab="后台管理系统" key="1">
             <Sliderarrow2 />
            </TabPane>
            <TabPane tab="综合大数据分析平台" key="2">
              <Sliderarrow1 />
            </TabPane>
          </Tabs>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: (<Link to='/about' >联系我们</Link>) } },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" class="logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
                  <Row gutter={50} justify="space-around">
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">产品</Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://lanxiang.co/#/index" target="_blank">蓝巷</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://testhr.lanxiang.co/#/home" target="_blank">橙事新人</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="http://mfs.huaerk.com/#/auth/login?redirect=%2F" target="_blank">小贷</a></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">关于我们</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/about'>联系我们</Link></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">解决方案</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/companyinfo'>集团信息化</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/humanresource'>人力资源</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/community'>智慧社区</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/finance'>金融科技</Link></Col>
                      </Row>
                    </Col>
                </Row>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 10,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">{`400-1669-335`}</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 京普网络科技（山东）有限公司 All rights reserved <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18051744号-2</a>
      </span>
    ),
  },
};
