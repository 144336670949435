import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import slider1 from '../../../img/qiehuan1.png';
import slider2 from '../../../img/qiehuan2.png';
import slider3 from '../../../img/qiehuan3.png';
import arrowleft from '../../../img/arrow-left.png';
import arrowright from '../../../img/arrow-right.png';



export default class Sliderarrow2 extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      className: "content1310-slider-wrapper-2",
      centerMode: true,
      arrows: false,
      dots: true
    };
    return (
      <div>
        <img className="content1310-arrow-left-2" onClick={this.previous} src={arrowleft} alt="arrow" />
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1}>
            <h3><img src={slider1} alt="slider" className="content1310-slider-img-2" /></h3>
          </div>
          <div key={2}>
            <h3><img src={slider2} alt="slider" className="content1310-slider-img-2" /></h3>
          </div>
          <div key={3}>
            <h3><img src={slider3} alt="slider" className="content1310-slider-img-2" /></h3>
          </div>
        </Slider>
        <img className="content1310-arrow-right-2" onClick={this.next} src={arrowright} alt="arrow" />
      </div>
    );
  }
}