//京普科技是区块链、云计算、物联网、大数据等技术的先行者，积极运用新技术探索行业解决方案。公司致力于提供一体化的解决方案，目前公司产品矩阵包含智慧社区解决方案、金融科技解决方案、人力资源解决方案、企业信息化解决方案。公司已经和多个行业领军企业建立了合作关系。
import React from 'react';
import logo from '../../../img/logo.png';
import infoimg from '../../../img/info.png';
import moneyimg from '../../../img/caiwu.png';
import supplyimg from '../../../img/gongyinglian.png';
import grouphumanimg from '../../../img/renli2.png';
import produceimg from '../../../img/shengchan.png';
import propertyimg from '../../../img/zichan.png';
import wxcode from '../../../img/wxcode.png';
import expandimg from '../../../img/tz.png';
import groupimg from '../../../img/jt.png';
import secimg from '../../../img/aq.png';
import sczz from '../../../img/sczz.png';
import rl from '../../../img/rl.png';
import { Divider, Tabs, Col, Row, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/community'>智慧社区</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/humanresource'>人力资源</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/companyinfo' >集团信息化</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/finance' >金融科技</Link>
    </Menu.Item>
  </Menu>
);

const { TabPane } = Tabs;


export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              解决方案
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about' style={{color: "#fff"}}>联系我们</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71so',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/us' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Group informatization',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '集团信息化解决方案',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '帮助企业实现全流程电子化管理，打破数据孤岛，实现知识共享，赋能企业提升产业链效能。',
      },
    ],
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content131-wrapper k93rk9pzks-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '信息化建设内容', className: 'title-h1' },
      {
        name: 'content',
        children:
          '集团信息化办公解决方案，帮助企业实现全流程电子化管理，打破数据孤岛、实现知识共享，赋能企业提升产业链效能。',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (<img src={infoimg} alt="info" className="content131-img"/>),
        className: 'title-content',
      },
    ],
  },
};
export const Feature04DataSource = {
  wrapper: { className: 'home-page-wrapper content04-wrapper' },
  page: { className: 'home-page content04' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content04-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content04-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content04-block-item',
          children: [
            {
              name: 'image',
              className: 'content04-block-icon',
              children: (<img src={expandimg} alt="img" className="content04-img" />),
            },
            {
              name: 'title',
              className: 'content04-block-title',
              children: '易拓展',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content04-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content04-block-item',
          children: [
            {
              name: 'image',
              className: 'content04-block-icon',
              children:
              (<img src={groupimg} alt="img" className="content04-img" />),
            },
            {
              name: 'title',
              className: 'content04-block-title',
              children: '集团化',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content04-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content04-block-item',
          children: [
            {
              name: 'image',
              className: 'content04-block-icon',
              children:
              (<img src={secimg} alt="img" className="content04-img" />),
            },
            {
              name: 'title',
              className: 'content04-block-title',
              children: '高安全',
            },
          ],
        },
      },
    ],
  },
};
export const Feature51DataSource = {
  wrapper: { className: 'home-page-wrapper content71-wrapper' },
  page: { className: ' content71' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '七大应用场景',
        className: 'title-h1',
      },
      {
        name: 'content0',
        children: '科技想象力，数据创造力',
        className: 'content71-explain',
      },
      { name: 'content',
        children: (
          <Tabs defaultActiveKey="1" tabPosition="left">
            <TabPane tab="企业门户体系" key="1">
              <div className="content71-bottom-wrapper">
                <div class="content71-bottom-title">企业门户体系满足全员个性化办公需求</div>
                <div className="content71-bottom-text">实现财务系统和业务系统数据对接，基于多系统的生产数据、费用数据、供应链数据等与财务真正实现集成；费控一体化管理：实现费用预算、申请、报销、付款等全周期管理，让费用控制更规范、透明、合理。</div>
                <div><img src={moneyimg} alt="apply" className="content71-bottom-img" /></div>
              </div>
            </TabPane>
            <TabPane tab="OA办公平台" key="2">
              <div className="content71-bottom-wrapper">
                <div class="content71-bottom-title">OA办公平台实现流程电子化，效率高</div>
                <div className="content71-bottom-text">拥有生产、制造、供应商管理、客户管理、供销存管理等多个业务系统，但系统互相孤立，数据割裂，孤岛丛生， 通过OA，集成各个业务系统，打通系统间的流程与数据，各系统审批流均可在OA中进行，相关数据返回至各系统， 实现数据互通。</div>
                <div><img src={propertyimg} alt="apply" className="content71-bottom-img"  /></div>
              </div>
            </TabPane>
            <TabPane tab="财务管理系统" key="3">
              <div className="content71-bottom-wrapper">
                <div class="content71-bottom-title">财务管理系统实现财务系统和业务系统数据对接</div>
                <div className="content71-bottom-text">实现财务系统和业务系统数据对接，基于多系统的生产数据、费用数据、供应链数据等与财务真正实现集成；费控一体化管理：实现费用预算、申请、报销、付款等全周期管理，让费用控制更规范、透明、合理。</div>
                <div><img src={grouphumanimg} alt="apply" className="content71-bottom-img"  /></div>
              </div>
            </TabPane>
            <TabPane tab="资产/知识管理系统" key="4">
              <div className="content71-bottom-wrapper">
                <div class="content71-bottom-title">资产/知识管理系统将企业资产数字化</div>
                <div className="content71-bottom-text">摒弃传统的人统计或者excel等模式，将企业资产数字化，并且有机集合大数据技术，将企业资产分类统计、优化企业损耗等。将分散的显性成果知识集中化管理；建立专家网络、知识仓库等，制定相应激励机制，深挖隐性知识；构建情报库：构建内外情报库、智力云（内外专家网），加强内外知识交流，助力研发创新。</div>
                <div><img src={produceimg} alt="apply" className="content71-bottom-img"  /></div>
              </div>
            </TabPane>
            <TabPane tab="人力资源管理系统" key="5">
              <div className="content71-bottom-wrapper">
                <div class="content71-bottom-title">人力资源管理系统让人力资源变的更简单</div>
                <div className="content71-bottom-text">人力资源管理系统除了与OA办公系统互通之外，主要对企业员工薪资 、招聘、培训、使用、考核、激励、调整等管理。该系统负责对公司内部组织架构管理，系统支持分发各级权限，甚至为各个分公司使用和管理。</div>
                <div><img src={supplyimg} alt="apply" className="content71-bottom-img"  /></div>
              </div>
            </TabPane>
            <TabPane tab="生产制造管理系统" key="6">
              <div className="content71-bottom-wrapper">
                <div class="content71-bottom-title">生产制造管理系统将生产制造过程数字化</div>
                <div className="content71-bottom-text">根据实际业务建立物料管理、物料需求、加工管理、托外管理、车间管理等模块，规范生产制造流程的同时让生产制造过程有据可查。结合大数据中台的分析能力，将生产制造过程数字化，优化生产过程。</div>
                <div><img src={sczz} alt="apply" className="content71-bottom-img" /></div>
              </div>
            </TabPane>
            <TabPane tab="供应链管理系统" key="7">
              <div className="content71-bottom-wrapper">
                <div class="content71-bottom-title">供应链管理系统提高企业经济效益</div>
                <div className="content71-bottom-text">对供应商进行管理，通过互联网进行安全、方便、自助服务式的业务协作。系统解决方案通过对供应链的基础信息、采购、客户关系、人力资源等核心构成进行模块进行统一整合，为企业建立供应链管控平台，实现统一采购、库存、销售，发挥供应链物流网络各个部分的协作效用，提高企业经济效益，满足市场与客户需求。</div>
                <div><img src={rl} alt="apply" className="content71-bottom-img" /></div>
              </div>
            </TabPane>
          </Tabs>
      ),
        className: 'content71-text-wrapper'
    },
    ],
  },
  tabsWrapper: { className: 'content71-tabs-wrapper' },
  // block: {
  //   children: [
  //     {
  //       name: 'block0',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'PHONE', className: 'content7-tag-name' },
  //         icon: { children: 'mobile' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //           md: 10,
  //           xs: 24,
  //         },
  //       },
  //     },
  //     {
  //       name: 'block1',
  //       tag: {
  //         className: 'content7-tag',
  //         icon: { children: 'tablet' },
  //         text: { className: 'content7-tag-name', children: 'TABLET' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //     {
  //       name: 'block2',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'DESKTOP', className: 'content7-tag-name' },
  //         icon: { children: 'laptop' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //     {
  //       name: 'elem~k93rlp61wrk',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'DESKTOP', className: 'content7-tag-name' },
  //         icon: { children: 'laptop' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //     {
  //       name: 'elem~k93rlps6rda',
  //       tag: {
  //         className: 'content7-tag',
  //         text: { children: 'DESKTOP', className: 'content7-tag-name' },
  //         icon: { children: 'laptop' },
  //       },
  //       content: {
  //         className: 'content7-content',
  //         text: {
  //           className: 'content7-text',
  //           md: 14,
  //           xs: 24,
  //           children: (
  //             <span>
  //               <h3>技术</h3>
  //               <p>
  //                 丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
  //               </p>
  //               <br />
  //               <h3>融合</h3>
  //               <p>
  //                 解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
  //               </p>
  //               <br />
  //               <h3>
  //                 开放
  //               </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
  //             </span>
  //           ),
  //         },
  //         img: {
  //           className: 'content7-img',
  //           md: 10,
  //           xs: 24,
  //           children:
  //             'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
  //         },
  //       },
  //     },
  //   ],
  // },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: ' content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '应用场景延伸',
        className: 'title-h1',
      },
      { name: 'content',
        children: (
          <Tabs defaultActiveKey="1" >
            <TabPane tab="电商板块" key="1">
              <div className="content7-bottom-text">作为有庞大C端市场的集团，内部和外部流量可以有效整合，员工内购、社区、缴费等场景下的流量可以源源不断的供应，因此电商版块可以内嵌到各个场景下，为经营添砖加瓦。</div>
              <div><img src={moneyimg} alt="apply" /></div>
            </TabPane>
            <TabPane tab="行业平台" key="2">
              <div className="content7-bottom-text">大中型企业自身在行业内具备一定的影响力和行业标杆属性，建立行业平台水到渠成。</div>
              <div><img src={propertyimg} alt="apply" /></div>
            </TabPane>
          </Tabs>
      ),
        className: 'content7-text-wrapper'
    },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: (<Link to='/about' >联系我们</Link>) } },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" class="logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
                  <Row gutter={50} justify="space-around">
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">产品</Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://lanxiang.co/#/index" target="_blank">蓝巷</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://testhr.lanxiang.co/#/home" target="_blank">橙事新人</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="http://mfs.huaerk.com/#/auth/login?redirect=%2F" target="_blank">小贷</a></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">关于我们</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/about'>联系我们</Link></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">解决方案</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/companyinfo'>集团信息化</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/humanresource'>人力资源</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/community'>智慧社区</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/finance'>金融科技</Link></Col>
                      </Row>
                    </Col>
                </Row>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 10,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">{`400-1669-335`}</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 京普网络科技（山东）有限公司 All rights reserved <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18051744号-2</a>
      </span>
    ),
  },
};

