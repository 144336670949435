import React from 'react';
import logo from '../../img/logo.png';
import wxcode from '../../img/wxcode.png';
import content135img from '../../img/content135img.png';
import { Divider, Col, Row, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import Sliderarrow1 from './Sliderarrow1';
import Sliderarrow2 from './Sliderarrow2';
import leftimg from '../../img/us/leftimg.png'
import changjing from '../../img/us/changjing.png'
import gaoxin from '../../img/us/gaoxin.png'
import jiejuefangan from '../../img/us/jiejuefangan.png'
import tuandui from '../../img/us/tuandui.png'
import line from '../../img/us/line.png'


const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/community'>智慧社区</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/humanresource'>人力资源</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/companyinfo' >集团信息化</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/finance' >金融科技</Link>
    </Menu.Item>
  </Menu>
);

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              解决方案
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about' style={{color: "#fff"}}>联系我们</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71so',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/us' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Jing Pu Network Technology',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '京普网络科技',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '探索极致用户体验与最佳工程实践探索',
      },
      // { name: 'button', className: 'banner3-button', children: '立即报名' },
    ],
  },
};
export const Content1311DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1311-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '关于我们', className: 'title-h1' },
      { name: 'content', children: '探索极致用户体验与最佳工程实践探索', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <Row justify="center">
            <Col lg={8} md={24} className="content1311-system-right"><img src={leftimg} className="content1311-right-img" alt="system" /></Col>
            <Col lg={7} md={24} className="content1311-system-left">
              <div className="content1311-text-wrapper">
                <div className="content1311-text-content">京普网络科技（山东）有限公司（以下简称京普科技）是青岛小桥金融信息服务有限公司（以下简称小桥金融）旗下孵化的科技公司，小桥金融是山东省内两家互联网金融高新技术企业之一。公司拥有一批长期专业从事软件开发、软件定制的专业人才，具有雄厚的技术开发实力，全方位满足政府与企业信息化需求。</div>
                <div className="content1311-text-content">京普科技是区块链、云计算、物联网、大数据等技术的先行者，积极运用新技术探索行业解决方案。公司致力于提供一体化的解决方案，目前公司产品矩阵包含智慧社区解决方案、金融科技解决方案、人力资源解决方案、企业信息化解决方案。公司已经和多个行业领军企业建立了合作关系。</div>
              </div>
            </Col>
          </Row>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content1312DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1312-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户的声音', className: 'title-h1' },
      {
        name: 'content',
        children:
          '众多企业的一致选择，众多用户的一致认可',
        className: 'title-content',
      },
      {
        name: 'content1',
        children:(<div></div>),
        className: 'title-bg',
      },
      {
        name: 'content2',
        children: (<Sliderarrow1 />),
        className: 'title-slider',
      },
    ],
  },
};
export const Content112DataSource = {
  OverPack: {
    className: 'home-page-wrapper content112-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', 
        children: '多样化定制服务', 
        className: 'title-h1' 
      },
      { name: 'title', 
        children: (
          <div>
            <Row justify="center">
              <Col className="content112-bottom-wrapper">
                <img src={changjing} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">为客户构建符合自身业务场景的平台</div>
              </Col>
              <Col className="content112-bottom-wrapper">
                <img src={gaoxin} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">荣获高新技术企业</div>
              </Col>
              <Col className="content112-bottom-wrapper">
                <img src={tuandui} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">提供专业的设计开发团队</div>
              </Col>
              <Col className="content112-bottom-wrapper">
                <img src={jiejuefangan} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">提供完整的一体化的解决方案</div>
              </Col>
            </Row>
            <img src={line} alt="img" className="content112-bottom-line" />
          </div>
        ), 
        className: 'content-wrapper' 
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content132-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '标杆案例', className: 'title-h1' },
      {
        name: 'content',
        children:
          '我们重视每一个对话者，确保业户合作关系的质量',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (<Sliderarrow2 />),
        className: 'title-content2',
      },
      {
        name: 'content3',
        children: ('蓝巷物业系统'),
        className: 'content132-expalin',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: (<Link to='/about' >联系我们</Link>) } },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" class="logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
                  <Row gutter={50} justify="space-around">
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">产品</Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://lanxiang.co/#/index" target="_blank">蓝巷</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://testhr.lanxiang.co/#/home" target="_blank">橙事新人</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="http://mfs.huaerk.com/#/auth/login?redirect=%2F" target="_blank">小贷</a></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">关于我们</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/about'>联系我们</Link></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">解决方案</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/companyinfo'>集团信息化</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/humanresource'>人力资源</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/community'>智慧社区</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/finance'>金融科技</Link></Col>
                      </Row>
                    </Col>
                </Row>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 10,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">{`400-1669-335`}</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 京普网络科技（山东）有限公司 All rights reserved <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18051744号-2</a>
      </span>
    ),
  },
};
