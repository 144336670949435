/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Link } from 'react-router-dom';
import { BackTop, Affix } from 'antd';
import up from '../../../img/up.png';
import tel from '../../../img/righttel.png';

import Nav2 from './Nav2';
import Banner3 from './Banner3';
import Banner5 from './Banner5';
import Feature8 from './Feature8';
import Pricing1 from './Pricing1';
import Feature1 from './Feature1';
import Feature0 from './Feature0';
import Content13 from './Content13';
import Content11 from './Content11';
import Footer1 from './Footer1';
import {
  Nav20DataSource,
  Banner30DataSource,
  Feature03DataSource,
  Feature80DataSource,
  Pricing11DataSource,
  Feature10DataSource,
  Banner51DataSource,
  Content138DataSource,
  Content110DataSource,
  Footer10DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

const style = {
  height: 40,
  width: 40,
  borderRadius: 4,
  backgroundColor: '#002766',
  position: 'relative',
  left: '100px',
  bottom: '200px'
};

const style1 = {
  width: '40px', 
  height: '140px',
  backgroundColor: '#002766', 
  position: 'absolute', 
  right: '0', 
  color: '#fff',
  borderRadius: 4,
  padding: '5px 15px',
};

export default class Humanresource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav2
        id="Nav2_0"
        key="Nav2_0"
        dataSource={Nav20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner3
        id="Banner3_0"
        key="Banner3_0"
        dataSource={Banner30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature0
        id="Feature0_0"
        key="Feature0_0"
        dataSource={Feature03DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature8
        id="Feature8_0"
        key="Feature8_0"
        dataSource={Feature80DataSource}
        isMobile={this.state.isMobile}
      />,
      <Pricing1
        id="Pricing1_0"
        key="Pricing1_0"
        dataSource={Pricing11DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_0"
        key="Feature1_0"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner5
        id="Banner5_0"
        key="Banner5_0"
        dataSource={Banner51DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_0"
        key="Content13_0"
        dataSource={Content138DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content11
        id="Content11_0"
        key="Content11_0"
        dataSource={Content110DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <Affix offsetTop={280} >
          <div style={style1}>
            <Link to="/about">
              <img src={tel} alt="tel" style={{position: 'relative', right: '8px', padding: '5px 0'}} />
              <div style={{color: '#fff'}}>联系我们</div>
            </Link>
          </div>
        </Affix>
        <BackTop visibilityHeight={800} ><img src={up} alt="up" style={style} /></BackTop>
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
