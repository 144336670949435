import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import slider1 from '../../img/img-center.png';
import slider2 from '../../img/img-center2.png';
import slider3 from '../../img/img-center3.png';
import arrowleft from '../../img/arrow-left.png';
import arrowright from '../../img/arrow-right.png';



export default class Sliderarrow2 extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      className: "content132-slider-wrapper",
      centerMode: true,
      arrows: false
    };
    return (
      <div>
        <img className="content132-arrow-left" onClick={this.previous} src={arrowleft} alt="arrow" />
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1}>
            <h3><img src={slider1} alt="slider" className="content132-slider-img" /></h3>
          </div>
          <div key={2}>
            <h3><img src={slider2} alt="slider" className="content132-slider-img" /></h3>
          </div>
          <div key={3}>
            <h3><img src={slider3} alt="slider" className="content132-slider-img" /></h3>
          </div>
        </Slider>
        <img className="content132-arrow-right" onClick={this.next} src={arrowright} alt="arrow" />
      </div>
    );
  }
}