//京普科技是区块链、云计算、物联网、大数据等技术的先行者，积极运用新技术探索行业解决方案。公司致力于提供一体化的解决方案，目前公司产品矩阵包含智慧社区解决方案、金融科技解决方案、人力资源解决方案、企业信息化解决方案。公司已经和多个行业领军企业建立了合作关系。
import React from 'react';
import logo from '../../../img/logo.png';
import { Divider } from 'antd';
import {Col, Row} from 'antd';
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import appimg from '../../../img/app.png';
import appicon from '../../../img/appicon.png';
import systemleftimg from '../../../img/systemleft.png';
import wxcode from '../../../img/wxcode.png';
import talentimg from '../../../img/rencai.png';
import onestopimg from '../../../img/yizhanshi.png';
import businessimg from '../../../img/yewu.png';
import recruitimg from '../../../img/zhaopin.png';
import joinimg from '../../../img/ruzhi.png';
import contractimg from '../../../img/zhishijianmo.png';
import payimg from '../../../img/xinchou.png';
import departimg from '../../../img/lizhi.png';
import humanarrowimg from '../../../img/humanarrow.png';
import htimg from '../../../img/hetong.png';
import employeeimg from '../../../img/yuangong.png';
import socialimg from '../../../img/shebao.png';
import moneyimg from '../../../img/xinchou2.png';
import computerimg from '../../../img/computer.png';
import chengappimg from '../../../img/chengshi.png';
import helpselfimg from '../../../img/zizhu.png';
import secimg from '../../../img/anquan.png';
import operateimg from '../../../img/caozuo.png';
import Sliderarrow2 from './Sliderarrow2';
const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/community'>智慧社区</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/humanresource'>人力资源</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/companyinfo' >集团信息化</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/finance' >金融科技</Link>
    </Menu.Item>
  </Menu>
);

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              解决方案
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about' style={{color: "#fff"}}>联系我们</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71so',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/us' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner30' },
  textWrapper: {
    className: 'banner30-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner30-name-en',
        children: 'Human Resources',
      },
      {
        name: 'slogan',
        className: 'banner30-slogan',
        children: '人力资源解决方案',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner30-name',
        children: '从HR工作流程出发，满足企业各运营部门管理需求，研发出"以HR管理为核心，集员工自助小程序、考勤、薪酬、审批、客户管理CRM于一体"的HR SaaS软件。',
      },
      { name: 'button', className: 'banner30-button', children: (<a href="https://testhr.lanxiang.co/#/home" target="_blank">了解更多</a>) },
    ],
  },
};
export const Feature03DataSource = {
  wrapper: { className: 'home-page-wrapper content03-wrapper' },
  page: { className: 'home-page content03' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { 
        name: 'title', 
        children: '产品特点' 
      },
      {
        name: 'content',
        children: '从您的关心出发，提供多方位的服务支持'
      }
    ],
  },
  childWrapper: {
    className: 'content03-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content03-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content03-block-item',
          children: [
            {
              name: 'image',
              className: 'content03-block-icon',
              children: (<img src={onestopimg} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content03-block-title',
              children: '一站式操作',
            },
            { name: 'content', children: '人力资源管理一站式线上操作，提升HR工作与组织效能' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content03-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content03-block-item',
          children: [
            {
              name: 'image',
              className: 'content03-block-icon',
              children: (<img src={talentimg} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content03-block-title',
              children: '造就人才闭环',
            },
            {
              name: 'content',
              children: '数据造就“选用育留”人才闭环，在职员工全生命周期管理',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content03-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content03-block-item',
          children: [
            {
              name: 'image',
              className: 'content03-block-icon',
              children: (<img src={businessimg} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content03-block-title',
              children: '一站式业务接入',
            },
            {
              name: 'content',
              children: '移动端与PC端将合同、HR、员工无缝对接',
            },
          ],
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: '根据HR全流程工作场景打造' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: 'https://hr.csxr.cn/#/auth/login?redirect=%2Fstaff%2Femployee', children: '立即体验', target: '_blank' },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 30,
          justify: "center",
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${humanarrowimg}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={recruitimg} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '招聘',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '招聘流程多人协同，面试入职流程信息化',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${humanarrowimg}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={joinimg} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '入职',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '员工扫码自助入职，规范流程',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${humanarrowimg}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={contractimg} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '合同',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '线上一键签署合同，安全又高效',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${humanarrowimg}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={payimg} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '薪酬',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '考勤、社保、个税一键核算，一键发放工资条',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child~k9dne88tmys',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${humanarrowimg}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={departimg} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '离职',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '细致入微做好员工关系完美过度离职期',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Pricing11DataSource = {
  wrapper: { className: 'home-page-wrapper pricing11-wrapper' },
  page: { className: 'home-page pricing11' },
  OverPack: { playScale: 0.3, className: 'pricing11-content-wrapper' },
  titleWrapper: {
    className: 'pricing11-title-wrapper',
    children: [
      { name: 'title', children: '产品介绍', className: 'pricing11-title-h1' },
      { name: 'content~k9dhfmfgfvr', className: 'pricing11-subtitle', children: '"橙事新人"移动互联基因强大，从HR工作流程出发，满足企业各运营部门管理需求，研发出"以HR管理为核心，集员工自助小程序、考勤、薪酬、审批、客户管理CRM于一体"的HR SaaS软件。' },
    ],
  },
  block: {
    className: 'pricing11-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing11-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing11-block-box active' },
          topWrapper: { className: 'pricing11-top-wrapper' },
          name: { className: 'pricing11-name', children: (<img src={socialimg} alt="img" />) },
          money: { className: 'pricing11-money', children: '社保管理' },
          content: {
            className: 'pricing11-content',
            children: (
              <span>
                所有员工的参保信息列表展示<br />
                员工入离职自动生成增减员计划<br />
                自动根据基数比例核算费用<br />
                生成完整的企业缴费清单<br />
              </span>
            ),
          },
          line: { className: 'pricing11-line' },
          buttonWrapper: {
            className: 'pricing11-button-wrapper',
            children: {
              a: {
                className: 'pricing11-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing11-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing11-block-box active' },
          topWrapper: { className: 'pricing11-top-wrapper' },
          name: { className: 'pricing11-name', children: (<img src={htimg} alt="img" />) },
          money: { className: 'pricing11-money', children: '合同管理' },
          content: {
            className: 'pricing11-content',
            children: (
              <span>
                在线创建合同模板，一键生成<br />
                合同在线编辑，即时发起签署<br />
                实现企业与员工一键签署<br />
                电子印章在线制作，印章在线验证<br />
              </span>
            ),
          },
          line: { className: 'pricing11-line' },
          buttonWrapper: {
            className: 'pricing11-button-wrapper',
            children: {
              a: {
                className: 'pricing11-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing11-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing11-block-box active' },
          topWrapper: { className: 'pricing11-top-wrapper' },
          name: { className: 'pricing11-name', children: (<img src={moneyimg} alt="img" />) },
          money: { className: 'pricing11-money', children: '薪酬管理' },
          content: {
            className: 'pricing11-content',
            children: (
              <span>
                联动全模块的薪酬核算体系<br />
                多维度薪酬报表<br />
                全面的薪酬福利体系<br />
                灵活设置薪酬结构和核算公式<br />
              </span>
            ),
          },
          line: { className: 'pricing11-line' },
          buttonWrapper: {
            className: 'pricing11-button-wrapper',
            children: {
              a: {
                className: 'pricing11-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block~k9dmue504z',
        className: 'pricing11-block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'pricing11-block-box active' },
          topWrapper: { className: 'pricing11-top-wrapper' },
          name: { className: 'pricing11-name', children: (<img src={employeeimg} alt="img" />) },
          money: { className: 'pricing11-money', children: '员工管理' },
          content: {
            className: 'pricing11-content',
            children: (
              <span>
                一站式便捷管理<br />
                线上操作便捷、省时<br />
                高效、极简、不出错<br />
                在线归档，随时查阅与结算<br />
              </span>
            ),
          },
          line: { className: 'pricing11-line' },
          buttonWrapper: {
            className: 'pricing11-button-wrapper',
            children: {
              a: {
                className: 'pricing11-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 12, xs: 24 },
  img: {
    children: `${computerimg}`,
  },
  textWrapper: { className: 'content1-text', md: 12, xs: 24 },
  title: { className: 'content1-title', children: '橙事新人后台管理端' },
  content: {
    className: 'content1-content',
    children:(
      <div>
        <div className="content1-explain">"橙事新人"移动互联基因强大，从HR工作流程系统化出发，满足企业各运营部门、各分支机构协同管理需求，研发出"以HR管理为核心，集员工自助小程序、考勤、薪酬、审批、于一体"的HR SaaS软件。</div>
        <a href="https://testhr.lanxiang.co/#/home" target="_blank">更多 ></a>
      </div>
    ),
      
  },
};
export const Banner51DataSource = {
  wrapper: { className: 'home-page-wrapper banner51' },
  page: { className: 'home-page banner51-page' },
  image: {
    className: 'banner51-image',
    children: `${chengappimg}`,
  },
  childWrapper: {
    className: 'banner51-title-wrapper',
    children: [
      {
        name: 'explain',
        className: 'banner51-explain',
        children: '橙事新人小程序端',
      },
      {
        name: 'content',
        className: 'banner51-content',
        children: '"员工轻轻点击，便可轻松完成打卡、审批、查看工资条、报销、签署合同、入职信息填写等流程；同时能够接收公司发布的公告及消息。',
      },
      {
        name: 'button',
        className: 'banner51-more-wrapper',
        children: {
          href: 'https://testhr.lanxiang.co/#/home',
          target: '_blank',
          className: 'banner51-more',
          children: '更多 >',
        },
      },
      {
        name: 'content',
        className: 'banner51-bottom-content',
        children: (
          <div>
            <Row gutter={50}>
              <Col>
                <img src={operateimg} alt="img" className="banner51-little-img" />
                <div className="banner51-little-text">操作简便</div>
              </Col>
              <Col>
                <img src={secimg} alt="img" className="banner51-little-img"/>
                <div className="banner51-little-text">安全高效</div>
              </Col>
              <Col>
                <img src={helpselfimg} alt="img" className="banner51-little-img"/>
                <div className="banner51-little-text">员工自助操作</div>
              </Col>
            </Row>
          </div>
          
        ),
      },
    ],
  },
  
};
export const Content138DataSource = {
  OverPack: {
    className: 'home-page-wrapper content138-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '案例展示', className: 'title-h1' },
      {
        name: 'content',
        children:
          '综合了集员工自助小程序、考勤、薪酬、审批、客户管理CRM于一体"的HR SaaS软件橙事新人已经上线，与华杰人力公司达成战略合作。',
        className: 'content138-title-explain',
      },
      {
        name: 'content2',
        children: (<Sliderarrow2 />),
        className: 'title-content2',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: (<Link to='/about' >联系我们</Link>) } },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" class="logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
                  <Row gutter={50} justify="space-around">
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">产品</Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://lanxiang.co/#/index" target="_blank">蓝巷</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://testhr.lanxiang.co/#/home" target="_blank">橙事新人</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="http://mfs.huaerk.com/#/auth/login?redirect=%2F" target="_blank">小贷</a></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">关于我们</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/about'>联系我们</Link></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">解决方案</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/companyinfo'>集团信息化</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/humanresource'>人力资源</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/community'>智慧社区</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/finance'>金融科技</Link></Col>
                      </Row>
                    </Col>
                </Row>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 10,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">{`400-1669-335`}</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 京普网络科技（山东）有限公司 All rights reserved <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18051744号-2</a>
      </span>
    ),
  },
};
