import React from 'react';
import logo from '../../../img/logo.png';
import wxcode from '../../../img/wxcode.png';
import dqdimg from '../../../img/duoqudao.png';
import dyhimg from '../../../img/duoyuanhua.png';
import higheffimg from '../../../img/gaoxiao.png';
import ktzimg from '../../../img/ketuozhan.png';
import costimg from '../../../img/chengben.png';
import completeimg from '../../../img/wanzheng.png';
import performimg from '../../../img/gaoxingneng.png';
import expandimg from '../../../img/tuozhan.png';
import { Divider } from 'antd';
import {Col, Row} from 'antd';
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import Sliderarrow1 from './Sliderarrow1';
import Sliderarrow2 from './Sliderarrow2';
import dcj from '../../../img/duochangjing.png';
import hk from '../../../img/huoke.png';
import yj from '../../../img/yongjin.png';
import yl from '../../../img/yingli.png';
const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/community'>智慧社区</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/humanresource'>人力资源</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/companyinfo' >集团信息化</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/finance' >金融科技</Link>
    </Menu.Item>
  </Menu>
);

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              解决方案
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about' style={{color: "#fff"}}>联系我们</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71so',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/us' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Financial Technology',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '金融科技平台',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '掌握最新客户数据与信息，与银行深耕合作在信贷业务领域深入发展，为企业提供更可靠的金融信息，成为银行与企业坚强的后盾，为企业的开拓提供支持。',
      },
      { name: 'button', className: 'banner3-button', children: '' },
    ],
  },
};
export const Content136DataSource = {
  OverPack: {
    className: 'home-page-wrapper content136-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '产品介绍', className: 'title-h1' },
      {
        name: 'content',
        children:
          '掌握最新客户数据与信息，与银行深耕合作在信贷业务领域深入发展，为企业提供更可靠的金融信息，成为银行与企业坚强的后盾，为企业的开拓提供支持。',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (<Sliderarrow1 />),
        className: 'title-content',
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: ' pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      { name: 'title', children: '大数据风控引擎', className: 'pricing1-title-h1' },
      { name: 'content~k9dhfmfgfvr', className: '', children: '为客户提供智能、精准、全面、高效的风控管理和专业化的风险咨询服务。' },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '核心技术应用' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                人脸识别技术引擎<br /> 文字识别技术引擎 <br /> 活体识别技术引擎<br />{' '}
                防Hack识别引擎<br /><br />
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '产品应用' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                人体对比<br />活体检测<br />银行文字识别<br />身份证文字识别<br />金融黑名单查询<br />
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: '场景应用' },
          money: { className: 'pricing1-money', children: '' },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                远程开户<br />在线金融<br />网上支付<br />登陆认证<br />证件核验<br />
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: '信贷SaaS系统 ',
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: '为客户提供智能、精准、全面、高效的风控管理和专业化的风险咨询服务。',
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(<img src={dqdimg} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '多渠道获客引流',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '可以通过多个渠道来引流客户，增加公司客户流量，提高收益。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children: (<img src={dyhimg} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '多元化产品功能',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '实现自动进件、自动审批、自动放款、自动还款、自动催收等环节。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(<img src={higheffimg} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '可扩展可定制',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '可以根据不同客户的需求来定制系统的内容，以满足客户的需要。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(<img src={ktzimg} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '系统高效稳定',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '系统提供了标准的接口，与P2P对接与引流平台对接更加高效稳定。',
            },
          ],
        },
      },
    ],
  },
};
export const Feature05DataSource = {
  wrapper: { className: 'home-page-wrapper content05-wrapper' },
  page: { className: 'home-page content05' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ 
      name: 'title', 
      children: '智能催收系统' 
    },{
      name: 'content',
      children: '专为金融机构及委外企业量身定制的，逾期账款催收管理系统',
    }],
  },
  childWrapper: {
    className: 'content05-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content05-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content05-block-item',
          children: [
            {
              name: 'image',
              className: 'content05-block-icon',
              children: (<img src={hk} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content05-block-title',
              children: '自带获客属性',
            },
            { name: 'content', children: '专为金融机构及委外企业量身定制的，逾期账款催收管理系统。' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content05-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content05-block-item',
          children: [
            {
              name: 'image',
              className: 'content05-block-icon',
              children: (<img src={yj} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content05-block-title',
              children: '独有佣金奖励',
            },
            {
              name: 'content',
              children: '进行高效的员工管理，使催收作业，管理员工更高效。',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content05-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content05-block-item',
          children: [
            {
              name: 'image',
              className: 'content05-block-icon',
              children: (<img src={dcj} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content05-block-title',
              children: '多场景化分类',
            },
            {
              name: 'content',
              children: '打通不良资产与催收机构，构建不良资产智能处置平台',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content05-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content05-block-item',
          children: [
            {
              name: 'image',
              className: 'content05-block-icon',
              children: (<img src={yl} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content05-block-title',
              children: '盈利点多',
            },
            {
              name: 'content',
              children: '免部署、免安装、在线入驻、低费、轻量易懂',
            },
          ],
        },
      },
    ],
  },
};
export const Content137DataSource = {
  OverPack: {
    className: 'home-page-wrapper content137-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '案例展示', className: 'title-h1' },
      {
        name: 'content',
        children:
          '综合了集客户管理，业务管理，推广管理，贷前贷后管理的小贷系统已经上线，与青岛惠民村镇银行达成战略合作。',
        className: 'content137-title-explain',
      },
      {
        name: 'content2',
        children: (<Sliderarrow2 />),
        className: 'title-content2',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: (<Link to='/about' >联系我们</Link>) } },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" class="logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
                  <Row gutter={50} justify="space-around">
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">产品</Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://lanxiang.co/#/index" target="_blank">蓝巷</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://testhr.lanxiang.co/#/home" target="_blank">橙事新人</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="http://mfs.huaerk.com/#/auth/login?redirect=%2F" target="_blank">小贷</a></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">关于我们</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/about'>联系我们</Link></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">解决方案</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/companyinfo'>集团信息化</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/humanresource'>人力资源</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/community'>智慧社区</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/finance'>金融科技</Link></Col>
                      </Row>
                    </Col>
                </Row>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 10,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">{`400-1669-335`}</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 京普网络科技（山东）有限公司 All rights reserved <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18051744号-2</a>
      </span>
    ),
  },
};

