import React from 'react';
import logo from '../../img/logo.png';
import contentbg from '../../img/contentbg.png';
import productimg from '../../img/chanpin.png';
import serviceimg from '../../img/fuwu.png';
import teamimg from '../../img/tuandui.png';
import businessimg from '../../img/yunying.png';
import techimg from '../../img/jishu.png';
import proimg from '../../img/zhuanye.png';
import diversityimg from '../../img/duoyanghua.png';
import contentimg from '../../img/content.png';
import company1 from '../../img/chenghe.png';
import company2 from '../../img/huajie.png';
import company3 from '../../img/yinhang.png';
import wxcode from '../../img/wxcode.png';
import jpkj_anli1 from '../../img/jpkj_anli1.png';
import jpkj_anli2 from '../../img/jpkj_anli2.png';
import jpkj_anli3 from '../../img/jpkj_anli3.png';
import jpkj_hezuo from '../../img/jpkj_hezuo.png';
import content135img from '../../img/content135img.png';
import { Divider, Col, Row, Menu, Dropdown, BackTop } from 'antd';
import { Link } from 'react-router-dom';
import Sliderarrow1 from './Sliderarrow1';
import Sliderarrow2 from './Sliderarrow2';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/community'>智慧社区</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/humanresource'>人力资源</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/companyinfo' >集团信息化</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/finance' >金融科技</Link>
    </Menu.Item>
  </Menu>
);

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              解决方案
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about' style={{color: "#fff"}}>联系我们</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71so',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/us' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner2-title k8y754vyvl6-editor_css',
          children: (
            <span>
              <p className='entitle'>Jing Pu Network Technology</p>
              <p>京普网络科技</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content',
          children: (
            <span>
              <span className="banner2-font-fff">探索极致用户体验与最佳工程实践探索</span>
            </span>
          ),
        },
        button: {
          className: 'banner2-button',
          children: (
                <Link to='/about' style={{color: "#fff"}}>联系我们</Link>
          ),
        },
      },
    ],
  },
};
export const Content135DataSource = {
  OverPack: {
    className: 'home-page-wrapper content135-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '京普网络科技、探索极致用户体验与最佳工程实践探索', className: 'title-h1' },
      {
        name: 'content2',
        children: (
              <div className="content135-bottom-wrapper">
                <img src={content135img} alt="img" className="content135-left-img" />
                <div className="content135-right" >
                  <div className="content135-right-title">京普网络科技</div>
                  <div className="content135-right-content">京普网络科技（山东）有限公司（以下简称京普科技）是青岛小桥金融信息服务有限公司（以下简称小桥金融）旗下孵化的科技公司，小桥金融是山东省内两家互联网金融高新技术企业之一。公司拥有一批长期专业从事软件开发、软件定制的专业人才，具有雄厚的技术开发实力，全方位满足政府与企业信息化需求。     
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;京普科技是区块链、云计算、物联网、大数据等技术的先行者，积极运用新技术探索行业解决方案。公司致力于提供一体化的解决方案，目前公司产品矩阵包含智慧社区解决方案、金融科技解决方案、人力资源解决方案、企业信息化解决方案。公司已经和多个行业领军企业建立了合作关系。</div>
                </div>
              </div>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '专业多样的解决方案', className: 'title-h1' },
      {
        name: 'content',
        children:
          '科技想象力，数据创造力',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (<Sliderarrow1 />),
        className: 'title-slider',
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0 k90qrftdecp-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: (<p class="content0-advan">我们的核心优势</p>) }],
  },
  childWrapper: {
    className: 'content0-block-wrapper k90qrxb6pds-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item k90r3nnpmaq-editor_css',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (
                <img src={productimg} alt="product" class="advantage" />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <p>成熟产品体系</p>
              ),
            },
            { name: 'content', children: (
              <p>Mature products</p>
            ), },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:(
                <img src={serviceimg} alt="service" class="advantage" />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '完善的服务体系',
            },
            {
              name: 'content',
              children: 'Perfect service',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:(
                <img src={teamimg} alt="team" class="advantage" />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '专业技术团队',
            },
            {
              name: 'content',
              children: 'Professional team',
            },
          ],
        },
      },
      {
        name: 'block~k90qwu5w3c',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:(
                <img src={businessimg} alt="business" class="advantage" />
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '自身运营团队',
            },
            {
              name: 'content',
              children: 'Senior operations',
            },
          ],
        },
      },
    ],
    gutter: 0,
    
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 24 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '我们提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '科技想象力，数据创造力',
      },
    ],
  },
  // img: {
  //   children: `${contentimg}`,
  //   className: 'content6-img',
  // },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            `${techimg}`,
          className: 'content6-icon',
        },
        title: { className: 'content6-title', children: '技术' },
        content: {
          className: 'content6-content',
          children:
            '公司拥有一批长期专业从事软件开发、软件定制的专业人才，具有雄厚的技术开发实力，全方位满足政府与企业信息化需求。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
          `${proimg}`,
        },
        title: { className: 'content6-title', children: '专业' },
        content: {
          className: 'content6-content',
          children:
            '公司具有自主知识产权的产品多达二十多款，目前已申请认证双软企业。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
          `${diversityimg}`,
        },
        title: { className: 'content6-title', children: '多样化' },
        content: {
          className: 'content6-content',
          children:
            '公司自主研发了“J1 Cloud”平台，为客户构建平台打下了坚实的基础，公司围绕自身场景不断延伸探索，使产品不断迭代更新，避免客户构建的平台“好看不好用”。',
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper feature0i-wrapper' },
  page: { className: 'home-page feature0i' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>标杆案例</p>
          </span>
        ),
        className: 'kidwzeweuvj-editor_css',
      },
      {
        name: 'content~kidwzic4jvq',
        className: 'kidwzvubq5-editor_css',
        children: (
          <span>
            <p>我们重视每一个对话者，确保业户合作关系的质量</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature0i-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'feature0i-block',
        md: 8,
        xs: 24,
        children: {
          className: 'feature0i-block-item',
          children: [
            {
              name: 'block',
              className: 'block-wra',
              children:
                (
                  <div className="block-wra">
                    <img src={jpkj_anli1} alt="feature" className="block-icon" />
                    <div className="block-wra-pading">
                      <span className="block-wra-title">职薪人人力资源系统</span>
                      <span className="block-wra-content">“职薪人”人力资源SaaS产品愿景是打造智能 化信息数据人力资源平台，以信息化为驱动构建生态化的人力资源交互平台，旨在帮助企业提升人力资源管理能力，激发组织员工和工作活力，改
                      善人资行业工作模式，全面提升人才管理效能。</span>
                    </div>
                  </div>
                ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'feature0i-block',
        md: 8,
        xs: 24,
        children: {
          className: 'feature0i-block-item',
          children: [
            {
              name: 'block',
              className: 'block-wra',
              children:
                (
                  <div className="block-wra">
                    <img src={jpkj_anli2} alt="feature" className="block-icon" />
                    <div className="block-wra-pading">
                      <span className="block-wra-title">蓝巷智慧社区</span>
                      <span className="block-wra-content">“蓝巷”智慧社区平台具备⾃主知识产权，可以随时随地根据实际场景调整业务形式和开发新功能。平台具备社区运营能⼒，丰富的产品服务商如阳光保险、阳光⼤姐、联通公司等可以为社区提供丰富多彩的服务。</span>
                    </div>
                  </div>
                ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'feature0i-block',
        md: 8,
        xs: 24,
        children: {
          className: 'feature0i-block-item',
          children: [
            {
              name: 'block',
              className: 'block-wra',
              children:
                (
                  <div className="block-wra">
                    <img src={jpkj_anli3} alt="feature" className="block-icon" />
                    <div className="block-wra-pading">
                      <span className="block-wra-title">小贷金融管理系统</span>
                      <span className="block-wra-content">小贷系统为客户提供智能、精准、全面、高效的风控管理和专业化的风险咨询服务。可以通过多个渠道来引流客户，增加公司客户流量，提高收益。实现自动进件、自动审批、自动放款、自动还款、自动催收等环节可以满足不同客户的需要。</span>
                    </div>
                  </div>
                ),
            },
          ],
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>标杆案例</p>
            </span>
          </span>
        ),
        className: 'pricing1-title-h1 kidyaykah6-editor_css',
      },
      {
        name: 'content~kidyb1065b',
        className: 'kidybq0217d-editor_css',
        children: (
          <span>
            <p>我们重视每一个对话者，确保业户合作关系的质量</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          content: {
            className: 'pricing1-content',
            children: (
              <div className="block-wra">
                <img src={proimg} alt="feature" className="block-icon" />
                <div className="block-wra-pading">
                  <span className="block-wra-title">职薪人人力资源系统</span>
                  <span className="block-wra-content">“职薪人”人力资源SaaS产品愿景是打造智能 化信息数据人力资源平台，以信息化为驱动构建生态化的人力资源交互平台，旨在帮助企业提升人力资源管理能力，激发组织员工和工作活力，改
                  善人资行业工作模式，全面提升人才管理效能。</span>
                </div>
              </div>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: '免费试用',
              },
            },
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: 'Starter' },
          money: { className: 'pricing1-money', children: '¥199' },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                14500-5Gbps<br />1410 GB-50TB（含）<br />141TB流量包<br />14国内按峰值宽带账单<br />14弹性计算<br />云服务器
                ECS
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: 'Pro' },
          money: { className: 'pricing1-money', children: '¥999' },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                14大于5Gbps<br />1450 GB-100TB（含）<br />145TB流量包<br />14国内按峰值宽带账单<br />14弹性计算<br />14云服务器
                ECS
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: '#',
                children: '立即购买',
              },
            },
          },
        },
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1321-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '公司荣誉', className: 'title-h1' },
      {
        name: 'content',
        children:
          '科技想象力，数据创造力',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (<Sliderarrow2 />),
        className: 'title-content2',
      },
      // {
      //   name: 'content3',
      //   children: ('蓝巷物业系统'),
      //   className: 'content1321-expalin',
      // },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '众多企业的选择，用实力完成客户托付', className: 'title-h1' },
      {
        name: 'content~k986nx4821f',
        className: '',
        children: (
          <div>
            <img src={jpkj_hezuo} alt="content12" className="content12-img" />
            {/* <Row justify="center">
              <Col className="content12-content">科技想象力，数据创造力</Col>
            </Row>
            <Row justify="space-around">
              <Col md={6} xs={12}><img src={company2} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company1} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
            </Row>
            <Row justify="space-around" className="mt24">
              <Col md={6} xs={12}><img src={company2} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company1} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
            </Row>
            <Row justify="space-around" className="mt24">
              <Col md={6} xs={12}><img src={company2} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company1} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
            </Row>
            <Row justify="space-around" className="mt24">
              <Col md={6} xs={12}><img src={company2} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company1} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
              <Col md={6} xs={12}><img src={company3} alt="company" /></Col>
            </Row> */}
          </div>
        ),
      },
    ],
  },
  // block: {
  //   className: 'img-wrapper',
  //   children: [
  //     {
  //       name: 'block0',
  //       className: 'block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         wrapper: { className: 'block-content' },
  //         img: {
  //           children: `${company1}`,
  //         },
  //       },
  //     },
  //     {
  //       name: 'block1',
  //       className: 'block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         wrapper: { className: 'block-content' },
  //         img: {
  //           children: `${company2}`,
  //         },
  //       },
  //     },
  //     {
  //       name: 'block2',
  //       className: 'block',
  //       md: 8,
  //       xs: 24,
  //       children: {
  //         wrapper: { className: 'block-content' },
  //         img: {
  //           children: `${company3}`,
  //         },
  //       },
  //     },
  //   ],
  // },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: (<Link to='/about' >联系我们</Link>) } },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" class="logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
                  <Row gutter={50} justify="space-around">
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">产品</Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://lanxiang.co/#/index" target="_blank">蓝巷</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://testhr.lanxiang.co/#/home" target="_blank">橙事新人</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="http://mfs.huaerk.com/#/auth/login?redirect=%2F" target="_blank">小贷</a></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">关于我们</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/about'>联系我们</Link></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">解决方案</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/companyinfo'>集团信息化</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/humanresource'>人力资源</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/community'>智慧社区</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/finance'>金融科技</Link></Col>
                      </Row>
                    </Col>
                </Row>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 10,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">{`400-1669-335`}</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 京普网络科技（山东）有限公司 All rights reserved <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18051744号-2</a>
      </span>
    ),
  },
};
