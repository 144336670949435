import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import cwimg from '../../../img/caiwuguanli.png';
import csimg from '../../../img/cuishou.png';
import dcimg from '../../../img/daichao.png';
import dsjimg from '../../../img/dashuju.png';
import xdimg from '../../../img/xindai.png';
import economyimg from '../../../img/jinrong.png';



export default class Sliderarrow1 extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      className: "content136-slider",
      centerMode: true,
      arrows: false,
      dots: true
    };
    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1}>
            <h3>
              <div className="content136-solution-wrapper">
                <div className="content136-img-wrapper"><img src={xdimg} alt="community" className="content136-solution-img" /></div>
                <div className="content136-solution-title">信贷SaaS系统</div>
                <div className="content136-solution-content">信贷SaaS系统是一款基于大数据智能风控的小额信贷系统。
通过整合各种智能化工具、数据、通信、规则实现自动进件、自动审批、自动放款、自动还款、自动催收等环节，使信贷业务的开展实现智能化、可控化、简单化、快速化。
系统内置一整套信贷风控体系，还为您提供了标准的接口，与P2P对接与引流平台对接、与车贷、抵押贷业务整合等，都可以通过这套系统实现。</div>
              </div>
            </h3>
          </div>
          <div key={2}>
            <h3>
              <div className="content136-solution-wrapper">
                <div className="content136-img-wrapper"><img src={dcimg} alt="community" className="content136-solution-img" /></div>
                <div className="content136-solution-title">贷超SaaS系统</div>
                <div className="content136-solution-content">贷超SaaS系统，提供一站式信用贷款技术服务平台。 通过汇聚海量贷款产品和首创的四维竞价模式 为有资金需求的借款人提供信用评估、智能推荐便捷申请、众评众测、还款提醒、授信提额等全流程贷款技术服务，最大化满足借款人“更多选择、更高额度、更快借到、更低利率”的借款需求。</div>
              </div>
            </h3>
          </div>
          <div key={3}>
            <h3>
              <div className="content136-solution-wrapper">
                <div className="content136-img-wrapper"><img src={csimg} alt="community" className="content136-solution-img" /></div>
                <div className="content136-solution-title">智能催收系统</div>
                <div className="content136-solution-content">智能催收系统，是专为金融机构及委外企业量身定制的，逾期账款催收管理系统。 提供一站式催收服务，操作便捷、数据稳定安全另有任务、考勤系统，进行高效的员工管理，使催收作业更高效。免部署、免安装、在线入驻、低费、轻量易懂平台突破传统催收模式，打通不良资产与催收机构，构建不良资产智能处置平台智能催收工具。 攻克传统催收痛点，全面开启智能催收！</div>
              </div>
            </h3>
          </div>
          <div key={4}>
            <h3>
              <div className="content136-solution-wrapper">
                <div className="content136-img-wrapper"><img src={dsjimg} alt="community" className="content136-solution-img" /></div>
                <div className="content136-solution-title">大数据风控引擎</div>
                <div className="content136-solution-content">大数据风控引擎是一款针对信贷风控领域，集 用户行为数据收集、线上审批、用户信用评价 ，及群体细分和信贷决策的全流程信贷风控决策平台深度学习核心引擎及技术，运用的决策引擎开发工具，整合个人、企业全景数据，利用专业的风控模型和大数据技术，进行分析与建模，完成全方位用户画像，确保征信信息真实可靠，为客户提供智能、精准、全面、高效的风控管理和专业化的风险咨询服务。</div>
              </div>
            </h3>
          </div>
          <div key={5}>
            <h3>
              <div className="content136-solution-wrapper">
                <div className="content136-img-wrapper"><img src={cwimg} alt="community" className="content136-solution-img" /></div>
                <div className="content136-solution-title">智能财务管理</div>
                <div className="content136-solution-content">银行级信贷核心，支持丰富的信贷产品定义，配置化的息费设置，灵活的扣款策略，完善的 入账逻辑, 同时支持多支付机构和多资方的接入 ，提供完整的账户状态报表以及支付机构对账体系。 在账户生命周期管理中，提供还款计划试算、建账、期供计算、扣款、还款、展期、减免、调账差</div>
              </div>
            </h3>
          </div>
        </Slider>
      </div>
    );
  }
}