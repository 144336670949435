//京普科技是区块链、云计算、物联网、大数据等技术的先行者，积极运用新技术探索行业解决方案。公司致力于提供一体化的解决方案，目前公司产品矩阵包含智慧社区解决方案、金融科技解决方案、人力资源解决方案、企业信息化解决方案。公司已经和多个行业领军企业建立了合作关系。
import React from 'react';
import logo from '../../img/logo.png';
import addressimg from '../../img/address.png';
import mailimg from '../../img/mail.png';
import telimg from '../../img/tel.png';
import wxcode from '../../img/wxcode.png';
import { Divider } from 'antd';
import {Col, Row} from 'antd';
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/community'>智慧社区</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/humanresource'>人力资源</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/companyinfo' >集团信息化</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/finance' >金融科技</Link>
    </Menu.Item>
  </Menu>
);

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              解决方案
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about' style={{color: "#fff"}}>联系我们</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71so',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/us' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Jing Pu Network Technology',
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '京普网络科技',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '探索极致用户体验与最佳工程实践探索',
      },
      // { name: 'button', className: 'banner3-button', children: '立即报名' },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content01-wrapper' },
  page: { className: 'home-page content01' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '联系我们' },
      { name: 'content', children: '和我们一起 颠覆行业', className: 'content01-explain' }
    ],
  },
  childWrapper: {
    className: 'content01-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content01-block',
        md: 7,
        xs: 24,
        
        children: {
          className: 'content01-block-item',
          children: [
            {
              name: 'image',
              className: 'content01-block-icon',
              children: (<img src={addressimg} alt="address" className="content01-img" />),
            },
            {
              name: 'title',
              className: 'content01-block-title',
              children: (<span>公司地址</span>),
            },
            { name: 'content', children: (<span>青岛市市北区连云港路76号 安泰广场1#1单元1204</span>) },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content01-block',
        md: 7,
        xs: 24,
        children: {
          className: 'content01-block-item',
          children: [
            {
              name: 'image',
              className: 'content01-block-icon',
              children: (<img src={mailimg} alt="mail" />),
            },
            {
              name: 'title',
              className: 'content01-block-title',
              children: '联系电话',
            },
            {
              name: 'content',
              children: '400-1669-335',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content01-block',
        md: 7,
        xs: 24,
        children: {
          className: 'content01-block-item',
          children: [
            {
              name: 'image',
              className: 'content01-block-icon',
              children: (<img src={telimg} alt="tel" />),
            },
            {
              name: 'title',
              className: 'content01-block-title',
              children: '企业邮箱',
            },
            {
              name: 'content',
              children: 'jpkj_xqjr@163.com'
            },
          ],
        },
      },
    ],
    gutter: 200,
    justify: "space-around"
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" class="logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
                  <Row gutter={50} justify="space-around">
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">产品</Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://lanxiang.co/#/index" target="_blank">蓝巷</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="https://testhr.lanxiang.co/#/home" target="_blank">橙事新人</a></Col>
                        <Col span={24} className="footer1-relevant-text"><a href="http://mfs.huaerk.com/#/auth/login?redirect=%2F" target="_blank">小贷</a></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">关于我们</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/about'>联系我们</Link></Col>
                      </Row>
                    </Col>
                    <Col md={8} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="footer1-relavant">解决方案</Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/companyinfo'>集团信息化</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/humanresource'>人力资源</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/community'>智慧社区</Link></Col>
                        <Col span={24} className="footer1-relevant-text"><Link to='/product/finance'>金融科技</Link></Col>
                      </Row>
                    </Col>
                </Row>
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 10,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">{`400-1669-335`}</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 京普网络科技（山东）有限公司 All rights reserved <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18051744号-2</a>
      </span>
    ),
  },
};

