import React from 'react'
import { Route, } from 'react-router-dom'
import Home from './views/Home'
import Community from './views/Product/Community'
import Humanresource from './views/Product/Humanresource'
import Companyinfo from './views/Product/Companyinfo'
import Finance from './views/Product/Finance'
import About from './views/About'
import Us from './views/Us'
import ScrollToTop from './ScrollToTop'

function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Route path="/" component={Home} exact />
        <Route path="/product/community" component={Community} />
        <Route path="/product/humanresource" component={Humanresource} />
        <Route path="/product/companyinfo" component={Companyinfo} />
        <Route path="/product/finance" component={Finance} />
        <Route path="/about" component={About} />
        <Route path="/us" component={Us} />
      </ScrollToTop>
    </div>
  );
}

export default App;
