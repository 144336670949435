import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import left from '../../img/us/left.png'
import right from '../../img/us/right.png'



export default class Sliderarrow1 extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      className: "content1312-slider-wrapper",
      centerMode: true,
      arrows: false,
      dots: true
    };
    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1}>
            <h3 className="content1312-h3-wrapper">
              <div className="content1312-solution-wrapper">
                <div className="content1312-img-wrapper"><img src={left} alt="community" className="content1312-solution-img-left" /></div>
                <div className="content1312-solution-content">华杰最终面对的是智能化发展，在人力资源的解决方面要为我们提供了便捷管理员工、在线签署合同、在线发起审批等一系列功能，不仅可以非常清晰的查看和分析员工的数据，还可以帮助我们处理一定的业务，节省了我们大大量的时间。</div>
                <div className="content1312-img-wrapper"><img src={right} alt="community" className="content1312-solution-img-right" /></div>
                <div className="content1312-solution-title">山东华杰人力资源管理有限公司</div>
              </div>
            </h3>
          </div>
          <div key={2}>
            <h3 className="content1312-h3-wrapper">
              <div className="content1312-solution-wrapper">
              <div className="content1312-img-wrapper"><img src={left} alt="community" className="content1312-solution-img-left" /></div>
                <div className="content1312-solution-content">通过使用蓝巷智慧社区平台，真正实现了智慧社区，为政府机关提供了社区综合大数据分析平台，使小区的居民生活更加安全、便捷，智能化，不仅提高了小区居民的生活品质，通过实现智慧社区助力实现了城市的智能化发展。</div>
                <div className="content1312-img-wrapper"><img src={right} alt="community" className="content1312-solution-img-right" /></div>
                <div className="content1312-solution-title">即墨阳光城</div>
              </div>
            </h3>
          </div>
          <div key={3}>
            <h3 className="content1312-h3-wrapper">
              <div className="content1312-solution-wrapper">
              <div className="content1312-img-wrapper"><img src={left} alt="community" className="content1312-solution-img-left" /></div>
                <div className="content1312-solution-content">京普科技根据我们的需求开发出的小贷后台管理页面，帮助我们银行更好的实现员工和产品的管理，在疫情期间配合开发出的h5页面，用户可以自助办理业务，帮助我们实现产品的推广，更好的助力公司的发展。</div>
                <div className="content1312-img-wrapper"><img src={right} alt="community" className="content1312-solution-img-right" /></div>
                <div className="content1312-solution-title">青岛即墨惠民村镇银行</div>
              </div>
            </h3>
          </div>
        </Slider>
      </div>
    );
  }
}