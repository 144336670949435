import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import communityimg from '../../img/shequ.png';
import humanresourceimg from '../../img/renli.png';
import groupimg from '../../img/jituan.png';
import economyimg from '../../img/jinrong.png';
import jpkj_pic1 from '../../img/jpkj_pic1.png';
import jpkj_pic2 from '../../img/jpkj_pic2.png';
import jpkj_pic3 from '../../img/jpkj_pic3.png';
import jpkj_pic4 from '../../img/jpkj_pic4.png';
import jpkj_01 from '../../img/jpkj_01.png';
import jpkj_02 from '../../img/jpkj_02.png';
import jpkj_03 from '../../img/jpkj_03.png';
import jpkj_04 from '../../img/jpkj_04.png';
import { Link } from 'react-router-dom';


export default class Sliderarrow1 extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      className: "content13-slider-wrapper",
      centerMode: true,
      arrows: false,
      dots: true
    };
    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1}>
            <h3>
            <Link to='/product/community'>
              <div className="content13-solution-wrapper">
                <div className="flex1">
                <div className="content13-img-wrapper">
                  <img src={jpkj_01} alt="community" className="content13-solution-img" />
                  <div className="content13-solution-title">智慧社区解决方案</div>
                  </div>
                <div className="content13-solution-content">“蓝巷”的产品愿景是打造智慧民生社区平台，以信息化为驱动，旨在通过先进适用技术应用和开发建设模式创新，综合运用信息科学和技术、消费方式，挖掘社区范围内外资源潜力，建设生态高效、信息发达、 经济繁荣新型现代化社区。</div>
                </div>
                <img src={jpkj_pic1} alt="community" className="content13-solution-img-right" />
              </div>
            </Link>
            </h3>
          </div>
          <div key={2}>
            <h3>
            <Link to='/product/humanresource'>
              <div className="content13-solution-wrapper">
                <div className="flex1">
                <div className="content13-img-wrapper">
                  <img src={jpkj_02} alt="community" className="content13-solution-img" />
                  <div className="content13-solution-title">人力资源解决方案</div>
                  </div>
                
                <div className="content13-solution-content">人力资源管理系统除了与OA办公系统互通之外，主要对企业员工薪资、招聘、培训、使用、考核、激励，调整等管理。</div>
                </div>
                <img src={jpkj_pic3} alt="community" className="content13-solution-img-right" />
                </div>
            </Link>
            </h3>
          </div>
          <div key={3}>
            <h3>
              <Link to='/product/companyinfo'>
                <div className="content13-solution-wrapper">
                  <div className="flex1">
                  <div className="content13-img-wrapper">
                    <img src={jpkj_03} alt="community" className="content13-solution-img" />
                    <div className="content13-solution-title">集团信息化解决方案</div>
                    </div>
                  
                  <div className="content13-solution-content">帮助企业实现全流程电子化管理，打破数据孤岛，实现知识共享，赋能企业提升产业链效能。</div>
                  </div>
                  <img src={jpkj_pic4} alt="community" className="content13-solution-img-right" />
                </div>
              </Link>
            </h3>
          </div>
          <div key={4}>
            <h3>
              <Link to='/product/finance'>
                <div className="content13-solution-wrapper">
                  <div className="flex1">
                    <div className="content13-img-wrapper">
                      <img src={jpkj_04} alt="community" className="content13-solution-img" />
                      <div className="content13-solution-title">金融科技平台</div>
                    </div>
                    <div className="content13-solution-content">京普科技掌握最新客户数据与信息，与银行深耕合作在信贷业务领域深入发展，为企业提供更可靠的金融信息，成为银行与企业坚强的后盾，为企业的开拓提供支持。</div>
                  </div>
                  <img src={jpkj_pic2} alt="community" className="content13-solution-img-right" />
                </div>
              </Link>
            </h3>
          </div>
        </Slider>
      </div>
    );
  }
}